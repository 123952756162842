<template>
  <div class="app-container">
    <van-cell-group class="block">
      <div class="cell" v-for="(item, index) in dataList" :key="index">
        <van-icon
          class="type"
          v-if="item.order_type === 11"
          name="after-sale"
        />
        <van-icon
          class="type"
          v-if="item.order_type === 12"
          name="ash-back-record"
        />
        <div class="main">
          <div class="tit" v-if="item.order_type === 11">
            <span class="txt">收入提成</span>
            <span class="status" v-if="item.confirm_status === 1"
              ><van-tag type="warning">未入账</van-tag></span
            >
          </div>
          <div class="tit" v-if="item.order_type === 12">提现</div>
          <span class="date">{{ item.created_at }}</span>
        </div>
        <span class="amount">+{{ item.amount / 100 }}</span>
      </div>

      <!-- <div class="cell">
        <van-icon class="type" name="after-sale" />
        <div class="main">
          <div class="tit">收入提成</div>
          <span class="date">2020年10月01日 13:09</span>
        </div>
        <span class="amount">-8.00</span>
      </div>

      <div class="cell">
        <van-icon class="type" name="cash-back-record" />
        <div class="main">
          <div class="tit">
            提现 <van-tag class="tag" plain type="primary">提现中</van-tag>
          </div>
          <span class="date">2020年10月01日 13:09</span>
        </div>
        <span class="amount">-8.00</span>
      </div> -->
    </van-cell-group>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dataList: [],
      query: {
        count: 10000
      }
    }
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    getDataList () {
      this.$axios.post('/wxc/balance/lists').then(res => {
        this.dataList = res.data.list
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.cell {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  padding: 10px 16px;
  .type {
    font-size: 34px;
    opacity: 0.6;
  }
  .main {
    flex: 1;
    padding: 0 10px;
    .tit {
      font-weight: 500;
      display: flex;
      align-items: center;
      .tag {
        margin-left: 5px;
      }
      .status{
        padding: 0 5px;
      }
    }
    .date {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .amount {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>